import React, { useRef, useEffect } from "react"
import Img from "gatsby-image"
import { useSelector } from "react-redux"
import { initVimeoPlayerWithControl } from "../../utils"
import CTABtn from "../../Button/CTA"
import Slider from "react-slick"
import Explore from "../Home/Explore"
import Join from "../Home/Join"
import Car from "../../../images/Articles/Service/car.svg"
import Tire from "../../../images/Articles/Service/tire.svg"
import Door from "../../../images/Articles/Service/door.svg"
import Wheel from "../../../images/Articles/Service/wheel.svg"
import styles from './index.module.scss'

export default function ArticleThree({ sanity }) {
  const isChinese = useSelector(state => state.lang.locale) === "tc"
  return (
    <div>
      <SectionIntro isChinese={isChinese} />
      <SectionTwo isChinese={isChinese} data={sanity} />
      <SectionThree isChinese={isChinese} data={sanity} />
      <SectionFour isChinese={isChinese} data={sanity} />
      <SectionFive isChinese={isChinese} data={sanity} />
      <SectionSix isChinese={isChinese} data={sanity} />
      <SectionSeven isChinese={isChinese} data={sanity} />
      <SectionEight isChinese={isChinese} />
      <Explore home={sanity.sanityHomePage} />
      <Join home={sanity.sanityHomePage} />
      <CTABtn />
    </div>
  )
}

function SectionIntro() {
  const video = useRef(null)

  useEffect(() => {
    video.current &&
      initVimeoPlayerWithControl({
        selector: video.current,
        desktopVid: "https://vimeo.com/442919385/2f75d0db0a",
        mobileVid: null,
      })
  }, [])

  return (
    <section>
      <div ref={video}></div>
    </section>
  )
}

function SectionTwo({ isChinese }) {
  return (
    <section className="py-32 xxl:py-40 md:py-20">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full md:px-12">
            <h2 className="quotes md:text-6xl bold-font mb-20 md:mb-10">
              90-Second with BMW Professional Vol. 2: BMW Service
            </h2>
            {/* <h2 className="quotes md:text-6xl bold-font mb-20 md:mb-10 font-bold">
              {isChinese ? "雨季檢查清單" : "Service Rainy Season Inspection"}
            </h2> */}
            <p className="large-text">
              {isChinese
                ? "第 2 集的 90-second with BMW Professional，為您帶來4個在雨水出行的小提示， 講解汽車雨季前應有的檢查，確保您的座駕無懼風雨，安全在道路上行駛!"
                : "In Episode 2, BMW Aftersales Service presents 4 tips for safely travelling in the rainy season. Introducing rainy inspection to ensure that your vehicle driving safe on the road."}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionThree({ isChinese, data }) {
  return (
    <section className="py-32 xxl:py-40 md:py-20 bg-brand-light-grey">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full md:px-12">
            <div className="image-container mb-32 xxl:mb-40">
              <Img fluid={data.articleimg.childImageSharp.fluid} />
            </div>
            <div className="flex md:flex-col">
              <div className="w-1/2 md:w-full pr-20">
                <div className="text-4xl xxl:text-5xl flex md:flex-col md:justify-center">
                  <div className={`${styles.iconContainer} w-20 mr-6 -mt-4 xxl:-mt-2 md:mr-0 md:self-center md:w-40 md:mb-10`}>
                    <img src={Wheel} alt="" className="w-full" />
                  </div>
                  <p className="bold-font font-bold md:w-full md:text-center md:mb-10">
                    {isChinese
                      ? "1. 檢查四輪輪胎和胎紋深度"
                      : "1. Inspecting your tyres’ tread patterns and pressure"}
                  </p>
                </div>
              </div>
              <div className="w-1/2 md:w-full pl-20 md:pl-0">
                <div className="flex">
                  <hr className="w-40 md:w-full h-2 bg-black mb-32" />
                </div>
                <p className="light-font text-4xl xxl:text-5xl">
                  {isChinese
                    ? "根據運輸署規定，胎紋最至少要深 1 毫米，並且起碼要蓋過輪胎面四分三的寬度。一般輪胎的胎紋到達中間的橫紋位置便需要更換，若果輪胎胎紋深度不足，在濕滑的路面會失去抓地力，令車輛不受控制。"
                    : "According to Transport Department regulations, the tread must be at least 1 mm depth, and cover the width of the tyre surface by three-quarters. Generally, the tyre tread needs to be replaced when it reaches the middle horizontal grain position. If the tyre tread depth is not enough, it will lose grip on wet roads and may increase the risk of making the vehicle uncontrolled."}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionFour({ isChinese, data }) {
  return (
    <section className="py-32 xxl:py-40 md:py-20 bg-brand-light-grey">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full md:px-12">
            <div className="image-container mb-32 xxl:mb-40">
              <Img fluid={data.articleimg2.childImageSharp.fluid} />
            </div>
            <div className="flex md:flex-col">
              <div className="w-1/2 md:w-full pr-20">
                <div className="text-4xl xxl:text-5xl flex md:flex-col md:justify-center">
                  <div className={`${styles.iconContainer} w-20 mr-6 -mt-4 xxl:-mt-2 md:mr-0 md:self-center md:w-40 md:mb-10`}>
                    <img src={Tire} alt="" className="w-full" />
                  </div>
                  <p className="bold-font font-bold md:w-full md:text-center md:mb-10">
                    {isChinese
                      ? "2. 檢查迫力皮、迫力碟及迫力油"
                      : "2. Inspecting your car’s brake pads, brake discs and brake oil"}
                  </p>
                </div>
              </div>
              <div className="w-1/2 md:w-full pl-20 md:pl-0">
                <div className="flex">
                  <hr className="w-40 md:w-full h-2 bg-black mb-32" />
                </div>
                <p className="light-font text-4xl xxl:text-5xl">
                  {isChinese
                    ? "隨着日常使用，迫力皮及迫力碟會耗蝕， 而迫力油亦會不足或出現有水和氣泡的狀況，甚至變了質。若迫力皮或迫力碟厚 度不足的話，會影響制動系統效能，影響行車安全。每一部車迫力皮碟厚度都標準厚度都不一樣，大家可以根據印在迫力碟的數字量度。"
                    : "If the thickness of the brake pad and brake disc is insufficient, it will affect the efficiency of the braking system and driving safety. The thickness of each car's brake pad and brake disc is different. You can measure it based on the number printed on the brake disc. The condition of the brake oil will also affect the effectiveness on the braking system."}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionFive({ isChinese, data }) {
  return (
    <section className="py-32 xxl:py-40 md:py-20">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full md:px-12">
            <div className="image-container mb-32 xxl:mb-40">
              <Img fluid={data.articleimg3.childImageSharp.fluid} />
            </div>
            <div className="flex md:flex-col">
              <div className="w-1/2 md:w-full pr-20">
                <div className="text-4xl xxl:text-5xl flex md:flex-col md:justify-center">
                  <div className={`${styles.iconContainer} w-20 mr-6 -mt-4 xxl:-mt-2 md:mr-0 md:self-center md:w-40 md:mb-10`}>
                    <img src={Door} alt="" className="w-full" />
                  </div>
                  <p className="bold-font font-bold md:w-full md:text-center md:mb-10">
                    {isChinese
                      ? "3. 排水管和防水膠邊加護處理"
                      : "3. Inspecting the drainage system and weather stripping, and testing the waterproofing"}
                  </p>
                </div>
              </div>
              <div className="w-1/2 md:w-full pl-20 md:pl-0">
                <div className="flex">
                  <hr className="w-40 md:w-full h-2 bg-black mb-32" />
                </div>
                <p className="light-font text-4xl xxl:text-5xl">
                  {isChinese
                    ? "車輛的細微地方亦不容忽視，在雨季來臨前亦需要留心全車排水管和防水 膠邊加護處理及排水管道異物處理， 確保管道暢通無阻。定期為車輛膠邊塗上原廠加護劑，能有效減慢因歲月及雨水侵蝕出現膠邊老化等問題，預防漏水及雨水流入車廂。"
                    : "Before the rainy season, it is also necessary to pay attention to the vehicle drainage pipe and the waterproof rubber edge. Applying the original rubber care to the rubber edge of the vehicle, which can effectively slow down the problem of aging of the rubber edge due to years and rain erosion, and prevent water leakage and rain from flowing into the car."}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionSix({ isChinese, data }) {
  return (
    <section className="py-32 xxl:py-40 md:py-20">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full md:px-12">
            <div className="image-container mb-32 xxl:mb-40">
              <Img fluid={data.articleimg4.childImageSharp.fluid} />
            </div>
            <div className="flex md:flex-col">
              <div className="w-1/2 md:w-full pr-20">
                <div className="text-4xl xxl:text-5xl flex md:flex-col md:justify-center">
                  <div className={`${styles.iconContainer} w-20 mr-6 -mt-4 xxl:-mt-2 md:mr-0 md:self-center md:w-40 md:mb-10`}>
                    <img src={Car} alt="" className="w-full" />
                  </div>
                  <p className="bold-font font-bold md:w-full md:text-center md:mb-10">
                    {isChinese
                      ? "4. 檢查水撥膠狀態及擋風玻璃鍍膜"
                      : "4. Inspecting water repellent and adding windscreen coating treatment"}
                  </p>
                </div>
              </div>
              <div className="w-1/2 md:w-full pl-20 md:pl-0">
                <div className="flex">
                  <hr className="w-40 md:w-full h-2 bg-black mb-32" />
                </div>
                <p className="light-font text-4xl xxl:text-5xl mb-20">
                  {isChinese
                    ? "最後，車主亦應檢查水撥膠狀態， 防止老化問題影響撥水效果。除此之外，為擋風玻璃及前車門兩側玻璃進行鍍膜，形成一個特殊的撥水層，增加 70%撥水效果*，便能令駕駛視野迅速回復清晰，對行車安全有更大的保證。"
                    : "Finally, checking the water repellent status to prevent the aging problem from affecting the water repellent effect. In addition, adding a windscreen coating on the windshield and both sides of the front door glasses can increase the water- repellent effect by 70%*. To ensure a clear vision while driving in the rainy season."}
                </p>
                <p className="light-font text-4xl xxl:text-5xl">
                  {isChinese
                    ? "*根據行車速度效果有所不同"
                    : "*the effect varies according to the driving speed"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionSeven({ data }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
  }
  return (
    <section className="py-32 xxl:py-40 md:py-20 article-slider overflow-hidden">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-8/12 md:w-full">
            <Slider {...settings}>
              <div className="slide h-full px-12 md:px-0">
                <Img fluid={data.articleimg5.childImageSharp.fluid} />
              </div>
              <div className="slide h-full px-12 md:px-0">
                <Img fluid={data.articleimg6.childImageSharp.fluid} />
              </div>
              <div className="slide h-full px-12 md:px-0">
                <Img fluid={data.articleimg7.childImageSharp.fluid} />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionEight({ isChinese }) {
  return (
    <section className="py-32 xxl:py-40 md:py-20 md:pt-56">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full md:px-12">
            <div className="flex">
              <div className="w-5/12 md:w-full">
                <div className="flex">
                  <hr className="w-40 md:w-full h-2 bg-black mb-32" />
                </div>
                <p className="text-4xl xxl:text-5xl bold-font font-bold mb-32 md:mb-16">
                  {isChinese
                    ? "做好雨季檢查及防水測試，以安全駕駛為上，無懼雨天出行。"
                    : "Give yourself and your family peace of mind with a Rainy Inspection!"}
                </p>
                <p className="text-4xl xxl:text-5xl light-font">
                  {isChinese
                    ? "更多資訊或預約查詢，可致電 3193 6688 或登入"
                    : "For more information, please contact 3193668 or visit "}
                  {isChinese ? (
                    <a href="https://bit.ly/rainy_tc">
                      https://bit.ly/rainy_tc
                    </a>
                  ) : (
                    <a href="https://bit.ly/rainy_en">
                      https://bit.ly/rainy_en
                    </a>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
